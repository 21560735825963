import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { getOrdersMenu } from "../../redux/actions/OrdersAction";
import { showToast } from "../../redux/actions/toastAction";
import sound from "../../assets/sound/newOrder.mp3";
import moment from "moment/moment";
import { hexToRgb } from "../../utils/commonFunctions";
import { getLocation } from "../../redux/actions/locationAction";

export default function Index(socket) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    socket.on("orderReload", (e) => {
      console.log("Reload==>", e);
      if (e.success) {
        if (e.data?.location === id) {
          getOrders(id);
          if (e.data.toast) {
            new Audio(sound).play();
            dispatch(showToast({ severity: "success", summary: e.message }));
          }
          if (e.data?.type === "error") {
            dispatch(showToast({ severity: "error", summary: e.message }));
          }
        }
      }
    });

    return () => {
      socket.off("orderReload");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  const [orderReadyWithIn, setOrderReadyWithIn] = useState(10);

  useEffect(() => {
    if (id) {
      dispatch(
        getLocation(id, (data) => {
          setOrderReadyWithIn(data?.orderReadyWithIn);
        })
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, [dispatch, id]);

  useEffect(() => {
    getOrders(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id]);

  const getOrders = (id) => {
    dispatch(getOrdersMenu(setLoading, id));
  };

  let { details } = useSelector((state) => state.orders);

  const [loading, setLoading] = useState(false);

  let latestOrders = details?.allOrders?.filter(
    (item) => item.status === "IN_QUE"
  );
  let completedOrders = details?.allOrders?.filter(
    (item) => item.status !== "IN_QUE"
  );
  completedOrders = completedOrders.reverse();
  const [initialTime, setInitialTime] = useState(null);
  const [currentTime, setCurrentTime] = useState(null);

  useEffect(() => {
    if (details?.today) {
      setInitialTime(details?.currentTime);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [details?.currentTime]);

  useEffect(() => {
    if (initialTime !== null) {
      setCurrentTime(getTimeStamp(initialTime));
      const interval = setInterval(() => {
        setCurrentTime((prevTime) => prevTime + 1000);
      }, 1000);
      return () => clearInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialTime]);

  const getTimeStamp = (t) => {
    return new Date(`1970-01-01T${t}`).getTime();
  };

  const formattedTime = moment(currentTime).format("HH:mm:ss");

  const getBetween = (c, p, d) => {
    if (c) {
      p = getTimeStamp(p);
      c = moment(c);
      let s = moment(p).subtract("minute", d);
      p = moment(p);
      return moment(c).isBetween(moment(s), moment(p));
    }
  };

  const getAfter = (c, p) => {
    if (c) {
      p = getTimeStamp(p);
      p = moment(p);
      c = moment(c);
      return moment(c).isAfter(moment(p));
    }
  };

  const getSame = (c, p) => {
    if (c) {
      p = getTimeStamp(p);
      p = moment(p);
      c = moment(c);
      return moment(c).isSame(moment(p));
    }
  };

  const getColor = (order) => {
    if (order?.type === "DINE_IN") {
      return "bg-yellow-100 border-yellow-500";
    } else if (order?.user?.role !== "USER") {
      if (order?.user?.color === "ffffff") {
        return "bg-cyan-100 border-cyan-300";
      } else {
        return "";
      }
    } else if (getSame(currentTime, order?.pickupTime)) {
      return "bg-green-300 border-green-500";
    } else if (
      getBetween(
        currentTime,
        order?.pickupTime,
        order?.preprationTime + orderReadyWithIn
      )
    ) {
      return "bg-green-200 border-green-400";
    } else if (
      getBetween(
        currentTime,
        order?.pickupTime,
        order?.preprationTime + orderReadyWithIn
      )
    ) {
      return "bg-green-100 border-green-300";
    } else if (
      getAfter(currentTime, order?.pickupTime) &&
      order?.status === "IN_QUE"
    ) {
      return "bg-red-100 border-red-400";
    } else if (order?.status === "READY") {
      return "bg-green-100 border-green-500";
    } else if (order?.status === "CANCELED") {
      return "bg-pink-100 border-pink-500";
    } else {
      return "surface-100 border-300";
    }
  };

  const getStyle = (order) => {
    if (order?.user?.color && order?.user?.color !== "ffffff") {
      return {
        backgroundColor: `${hexToRgb("#" + order?.user?.color, "0.6")}`,
        border: `1px solid ${hexToRgb("#" + order?.user?.color)}`,
      };
    }
  };

  return {
    latestOrders,
    completedOrders,
    loading,
    history,
    id,
    formattedTime,
    getColor,
    getStyle,
  };
}
