import { Calendar } from "primereact/calendar";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  addTempBusySlotsInLocation,
  getTempBusySlotsInLocation,
  removeTempBusySlotsInLocation,
} from "../../redux/actions/locationAction";
import PrimaryButton, {
  PrimaryButtonOutlined,
} from "../../shared/Button/PrimaryButton";
import { setTime } from "../../utils/commonFunctions";

export default function TempSlots({ info, onClose }) {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    startTime: "",
    endTime: "",
  });

  useEffect(() => {
    if (info?.location) {
      dispatch(
        getTempBusySlotsInLocation(info.location, (e) => {
          setData({
            startTime: setTime(e.startTime),
            endTime: setTime(e.endTime),
          });
        })
      );
    }
  }, [info]);

  const handleChange = (e) => {
    let { name, value } = e.target;
    setData((prev) => ({ ...prev, [name]: value }));
  };

  const onSubmit = () => {
    if (data?.startTime && data?.endTime) {
      dispatch(
        addTempBusySlotsInLocation(
          { ...data, location: info.location },
          setLoading,
          onClose
        )
      );
    }
  };

  const onRemove = () => {
    if (info.location) {
      dispatch(removeTempBusySlotsInLocation(info.location, onClose));
    }
  };
  return (
    <div className="grid">
      <div className="col-12 md:col-6">
        <div className="mb-1">Start Time</div>
        <Calendar
          id="startTime"
          name="startTime"
          value={data?.startTime}
          onChange={handleChange}
          timeOnly
          hourFormat="24"
        />
      </div>
      <div className="col-12 md:col-6">
        <div className="mb-1">End Time</div>
        <Calendar
          id="endTime"
          name="endTime"
          value={data?.endTime}
          onChange={handleChange}
          timeOnly
          hourFormat="24"
        />
      </div>
      <PrimaryButton label="Save" onClick={onSubmit} loading={loading} />
      <PrimaryButton severity="warning" label="Remove" onClick={onRemove} />
      <PrimaryButtonOutlined label="Cancel" onClick={onClose} />
    </div>
  );
}
