import React from "react";
import TableCard from "../../shared/TableCard/TableCard";
import { useDispatch } from "react-redux";
// import { convert24HourTo12Hour } from "../../utils/commonFunctions";
import { ChangeOrderStatusAction } from "../../redux/actions/OrdersAction";

import { Tag } from "primereact/tag";
import { confirmPopup } from "primereact/confirmpopup";
export default function OrderDetailsTable({
  latestOrders,
  completedOrders,
  locationId,
  formattedTime,
  getColor,
  getStyle,
}) {
  const dispatch = useDispatch();

  const handleCheck = (id, status) => {
    dispatch(ChangeOrderStatusAction(id, locationId, status));
  };

  const switchTemplate = (rowData) => {
    return (
      <div className="flex justify-content-around">
        <>
          <i
            className="pi text-green-500 pi-check-circle mx-2 cursor-pointer text-3xl"
            onClick={() => handleCheck(rowData?._id, "READY")}
          ></i>
          <i
            className="pi text-red-500 pi-times-circle mx-2 cursor-pointer text-3xl"
            onClick={(e) => confirm(e, rowData?._id)}
          ></i>
        </>
      </div>
    );
  };

  const confirm = (event, id) => {
    confirmPopup({
      target: event.currentTarget,
      message: "Are you sure you want to cancel?",
      icon: "pi pi-exclamation-triangle",
      accept: () => handleCheck(id, "CANCELED"),
      reject: () => {},
    });
  };

  const addOns = (rowData) => {
    const items = rowData?.selectedAddOns.map((item) => item?.name);
    return <span className="text-green-500">{items.toString()} </span>;
  };
  const Ingredients = (rowData) => {
    const items = rowData?.removedIngredients.map((item) => item?.name);
    return <span className="text-red-500">{items.toString()} </span>;
  };

  const time = (rowData) => {
    // return <>{convert24HourTo12Hour(rowData?.pickupTime)}</>;
    return <>{rowData?.pickupTime}</>;
  };

  const statusBodyTemplate = (data) => {
    return <Tag value={data.status} severity={getSeverity(data.status)}></Tag>;
  };

  const getSeverity = (status) => {
    switch (status) {
      case "READY":
        return "success";
      case "CANCELED":
        return "danger";
      default:
        return "success";
    }
  };

  const getPrice = (item) => {
    return parseFloat(item).toFixed(2);
  };

  const paymentStatus = (item) => {
    let paymentStatus = "";

    if (item.invoiceId) {
      paymentStatus = "Paid";
      if (item.onlinePaymentStatus === "PAID") {
        paymentStatus = "Paid:Mobile";
      }
    }
    return paymentStatus;
  };

  return (
    <>
      {formattedTime}
      <TableCard title="Latest Orders Details">
        <div className="grid surface-ground font-bold p-2 largescreen-text">
          {/* <div className="col-1">Name</div> */}
          <div className="col-3">Kontakt</div>
          <div className="col-1">Zeit</div>
          <div className="col-5 m-0">
            <div className="grid">
              <div className="col-1">X</div>
              <div className="col-3">Produkt</div>

              <div className="col-6">Ohne/Extra</div>
            </div>
          </div>
          <div className="col-1">Preise</div>
          <div className="col-1">ZahlSt</div>
          <div className="col-1 text-center">Status</div>
        </div>

        {latestOrders.map((order) => (
          <div
            className={`grid p-2 border-1 mb-3   ${getColor(
              order
            )}  shadow-1 font-semibold largescreen-text`}
            style={getStyle(order)}
          >
            {/* <div className="col-1">{order?.user?.name || "-"}</div> */}
            <div className="col-3" style={{ overflowX: "auto" }}>
              {order?.user?.name ? <div>{order?.user?.name}</div> : null}

              {order?.user?.role === "USER" && (
                <>
                  {order?.user?.email ? (
                    <>
                      {order?.user?.email}
                      {order?.user?.mobile ? (
                        <div className="text-xs">
                          {order?.user?.countryCode}
                          {order?.user?.mobile}
                        </div>
                      ) : null}
                    </>
                  ) : (
                    <>
                      {order?.user?.countryCode}
                      {order?.user?.mobile}
                    </>
                  )}
                </>
              )}
            </div>
            <div className="col-1 font-bold">{time(order)}</div>

            <div className="col-5 m-0">
              {order?.cart?.cartItems.map((item, i) => (
                <div className="grid">
                  <div className="col-1">{item?.qty}</div>
                  <div className="col-3 font-medium">{item?.product?.name}</div>

                  <div className="col-6 font-medium">
                    {Ingredients(item)}
                    {addOns(item)}
                  </div>
                  {/* <div className="col-2 font-medium">€ {getPrice(item)}</div> */}
                </div>
              ))}
            </div>
            <div className="col-1 font-medium">
              € {getPrice(order?.cart?.cartGrandTotal)}
            </div>
            <div className="col-1 font-medium">{paymentStatus(order)}</div>
            <div className="col-1">{switchTemplate(order)}</div>
          </div>
        ))}
      </TableCard>
      <br />
      <TableCard title="Completed Orders Details">
        <div className="grid surface-ground font-bold p-2 largescreen-text">
          {/* <div className="col-1">Name</div> */}
          <div className="col-3">Kontakt</div>
          <div className="col-1">Zeit</div>
          <div className="col-5 m-0">
            <div className="grid">
              <div className="col-1">X</div>
              <div className="col-3">Produkt</div>

              <div className="col-6">Ohne/Extra</div>
            </div>
          </div>
          <div className="col-1">Preise</div>
          <div className="col-1">ZahlSt</div>
          <div className="col-1 text-center">Status</div>
        </div>

        {completedOrders.map((order) => (
          <div
            className={`grid p-2 border-1 mb-3 ${getColor(
              order
            )}  shadow-1 font-semibold largescreen-text`}
            style={getStyle(order)}
          >
            {/* <div className="col-1">{order?.user?.name || "-"}</div> */}
            <div className="col-3" style={{ overflowX: "auto" }}>
              {order?.user?.name ? <div>{order?.user?.name}</div> : null}
              {order?.user?.email ? (
                <>
                  {order?.user?.email}
                  {order?.user?.mobile ? (
                    <div className="text-xs">
                      {order?.user?.countryCode}
                      {order?.user?.mobile}
                    </div>
                  ) : null}
                </>
              ) : (
                <>
                  {order?.user?.countryCode}
                  {order?.user?.mobile}
                </>
              )}
            </div>
            <div className="col-1 font-bold">{time(order)}</div>

            <div className="col-5 m-0">
              {order?.cart?.cartItems.map((item, i) => (
                <div className="grid">
                  <div className="col-1">{item?.qty}</div>
                  <div className="col-3 font-medium">{item?.product?.name}</div>

                  <div className="col-6 font-medium">
                    {Ingredients(item)}
                    {addOns(item)}
                  </div>
                </div>
              ))}
            </div>
            <div className="col-1 font-medium">
              € {getPrice(order?.cart?.cartGrandTotal)}
            </div>
            <div className="col-1 font-medium">{paymentStatus(order)}</div>
            <div className="col-1 text-center">{statusBodyTemplate(order)}</div>
          </div>
        ))}
      </TableCard>
    </>
  );
}
