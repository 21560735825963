import React, { useEffect } from "react";
import LocationContainer from "./LocationContainer";
import {
  CustomForm,
  CustomInput,
  CustomInputNumber,
  CustomTextArea,
} from "../../../shared/Input/AllInputs";
import DayAndTimeComponent from "./DayAndTimeComponent";
import PrimaryButton from "../../../shared/Button/PrimaryButton";
import { Card } from "primereact/card";
export default function LocationForm({ type }) {
  const {
    locationData,
    days,
    handleDay,
    weekDay,
    handleChangeOpeningTime,
    handleChange,
    loading,
    onSubmit,
    setFormType,
    readyToOrder,
    autoConnectReadyToOrder,
    loading1,
  } = LocationContainer();
  useEffect(() => {
    setFormType(type);
  }, [type, setFormType]);

  return (
    <>
      <CustomForm style={type === "VIEW" ? { pointerEvents: "none" } : {}}>
        <CustomInput
          label="Branch Location Name"
          name="name"
          required
          data={locationData}
          onChange={handleChange}
        />
        <CustomInput
          label="Branch Location Address"
          name="address"
          data={locationData}
          onChange={handleChange}
        />
        <CustomTextArea
          label="Tag Line"
          name="tagLine"
          data={locationData}
          onChange={handleChange}
        />{" "}
        <CustomInputNumber
          label="Order Ready In"
          name="orderReadyWithIn"
          required
          data={locationData}
          onChange={handleChange}
          showButtons
          min={0}
          suffix=" Min"
          buttonLayout="horizontal"
        />{" "}
        <CustomInputNumber
          label="Order Cancel Before"
          name="orderCancelBefore"
          required
          data={locationData}
          onChange={handleChange}
          showButtons
          min={0}
          suffix=" Min"
          buttonLayout="horizontal"
        />
        <div className="col-12">
          <CustomInputNumber
            label="Buffer Time for Orders"
            name="bufferTime"
            required
            data={locationData}
            onChange={handleChange}
            showButtons
            min={0}
            suffix=" Min"
            buttonLayout="horizontal"
            col={6}
          />{" "}
        </div>
        {locationData.accountAccessToken ? (
          <div className="col-3">
            <Card
              title="Ready2Order"
              subTitle={readyToOrder?.company_name}
              header={<img alt="Card" src={readyToOrder?.company_logo} />}
            >
              <span>Company A/C: {readyToOrder?.company_accountNumber}</span>
              <br />
              <span>Company ID: {readyToOrder?.company_id}</span>
              <br />
              <span>User Name: {readyToOrder?.company_username}</span> <br />
              <span>Mobile: {readyToOrder?.company_businessPhoneNumber}</span>
              <br />
              <span>User A/C No.: {readyToOrder?.company_accountNumber}</span>
              <br />
            </Card>
          </div>
        ) : null}
        <div className="col-2 mx-2">
          <PrimaryButton
            label="Auto Connect Ready2Order"
            loading={loading1}
            onClick={autoConnectReadyToOrder}
            col={6}
          />
        </div>
        <DayAndTimeComponent
          days={days}
          handleDay={handleDay}
          weekDay={weekDay}
          handleChangeOpeningTime={handleChangeOpeningTime}
        />
        {type !== "VIEW" && (
          <PrimaryButton
            label="Save Branch Location"
            loading={loading}
            onClick={onSubmit}
            col={6}
          />
        )}
      </CustomForm>
    </>
  );
}
