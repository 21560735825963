import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import TableCard from "../../shared/TableCard/TableCard";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputSwitch } from "primereact/inputswitch";
import { useDispatch } from "react-redux";
import { EnableDisableLocation } from "../../redux/actions/locationAction";
import TempSlots from "./TempSlots";
import { getMyRole } from "../../services/auth";

export default function CustomerTable({
  allLocations,
  loading,
  history,
  search,
  setSearch,
  setLoading,
}) {
  const dispatch = useDispatch();

  const [visible, setVisible] = useState(null);
  let role = getMyRole();

  const actions = (d) => {
    return (
      <div className="flex">
        <i
          className="pi pi-eye cursor-pointer mx-2"
          onClick={() => history.push(`/locations/view/${d._id}`)}
        ></i>
        <i
          className="pi pi-pencil cursor-pointer mx-2"
          onClick={() => history.push(`/locations/edit/${d._id}`)}
        ></i>
        {role === "ADMIN" && (
          <i
            className="pi pi-stopwatch cursor-pointer mx-2"
            onClick={() =>
              setVisible({ location: d._id, locationName: d.name })
            }
          ></i>
        )}
      </div>
    );
  };

  const handleSwitchChange = (id, active) => {
    dispatch(EnableDisableLocation(id, active));
  };
  const switchTemplate = (rowData) => {
    return (
      <div className="flex">
        <InputSwitch
          checked={rowData?.isActive}
          onChange={(e) => handleSwitchChange(rowData?._id, e.value)}
        />
        <p style={{ marginLeft: "5px" }}>{rowData?.isActive ? "On" : "Off"}</p>
      </div>
    );
  };

  const onClose = () => {
    setVisible(null);
  };

  return (
    <TableCard
      title="Manage Branch Location"
      onSearch={setSearch}
      searchKeyword={search}
      buttonTitle="Add Location"
      linkTo="/locations/add"
      extraButtons={[{ buttonTitle: "Sort", linkTo: "/locations/reorder" }]}
    >
      <DataTable
        paginator
        value={allLocations}
        className=""
        showGridlines
        rows={10}
        loading={loading}
        responsiveLayout="scroll"
        emptyMessage="No location found."
      >
        <Column
          field="name"
          header="Branch Name"
          style={{ width: "250px" }}
        ></Column>
        <Column
          field="address"
          header="Address"
          style={{ width: "250px" }}
        ></Column>
        <Column
          field="tagLine"
          header="Tag Line"
          style={{ width: "250px" }}
        ></Column>
        <Column
          field="status"
          header="Status"
          body={switchTemplate}
          style={{ width: "250px" }}
        ></Column>
        <Column
          body={actions}
          style={{ width: "250px" }}
          header="Action"
        ></Column>
      </DataTable>

      <Dialog
        header={visible?.locationName}
        visible={visible}
        onHide={onClose}
        resizable={false}
        draggable={false}
        style={{ width: "30vw" }}
      >
        <TempSlots info={visible} onClose={onClose} />
      </Dialog>
    </TableCard>
  );
}
