export default class Constants {
  static END_POINT = {
    LOGIN: "/login",
    PROFILE: "/profile",
    FORGET_PASSWORD: "/forget-password",
    UPDATE_PROFILE: "/update",
    RESET_PASSWORD: "/change-forget-password",
    UPLOAD_FILE: "/upload-file",
    CHANGE_PASSWORD: "/change-password",

    //Dashborad

    GET_USERS_COUNT: "/count",

    //Customer

    CUSTOMER: "/users",
    VIEW_CUSTOMER: "/user/",
    ENABLE_DISABLE_CUSTOMER: "/enable-disable-user/",

    LOCATION: "/location/",
    LOCATION_BUSY_SLOT: "/temp-busy-slot/",

    //Location Menu

    MENU: "/category/",

    //Products

    PRODUCTS: "/product/",
    R2O_PRODUCTS: "/ready2Order/products/",

    ORDERS: "/order/",
    CHANGE_ORDER_STATUS: "/change-order-status/",

    //Employee

    EMPLOYEE: "/employee/",
    ENABLE_DISABLE_EMPLOYEE: "/enable-disable-employee/",

    //Ingredients

    INGREDIENTS: "/ingredient/",

    //Add -On

    ADD_ON: "/addon/",
    ENABLE_DISABLE_ADD_ON: "/enable-disable-add-on/",
  };
}
