import api from "../../services/api";
import { types } from "../types/types";
import Constants from "../../services/constant";
import { showToast } from "./toastAction";
import { getFormattedTime } from "../../utils/commonFunctions";
import { showLoaderAction, hideLoaderAction } from "./loaderAction";

const getLocations = (setLoading) => async (dispatch) => {
  if (setLoading) {
    setLoading(true);
  }
  const res = await api("get", Constants.END_POINT.LOCATION);
  if (res.success) {
    if (res.data) {
      dispatch({
        type: types.CHANGE_LOCATIONS,
        payload: res.data,
      });
    }
  }
  if (setLoading) {
    setLoading(false);
  }
};

const addBranchLocation =
  (locationData, days, setLoading, history) => async (dispatch) => {
    setLoading(true);

    let openDays = days.map((item, i) => ({
      weekDay: item.weekDay,
      isClosed: item.isClosed,
      openTime: item.openTime ? getFormattedTime(item.openTime) : "",
      closeTime: item.closeTime ? getFormattedTime(item.closeTime) : "",
      rushHourStartTime: item.closeTime
        ? getFormattedTime(item.rushHourStartTime)
        : "",
      rushHourEndTime: item.closeTime
        ? getFormattedTime(item.rushHourEndTime)
        : "",
    }));

    const payload = {
      ...locationData,
      openDays,
    };

    const res = await api("post", Constants.END_POINT.LOCATION, payload);
    if (res.success) {
      dispatch(getLocations());
      history.goBack();
      dispatch(showToast({ severity: "success", summary: res.message }));
    }
    setLoading(false);
  };

const editBranchLocation =
  (id, locationData, days, setLoading, history) => async (dispatch) => {
    setLoading(true);

    let openDays = days.map((item, i) => ({
      weekDay: item.weekDay,
      isClosed: item.isClosed,
      openTime: item.openTime ? getFormattedTime(item.openTime) : "",
      closeTime: item.closeTime ? getFormattedTime(item.closeTime) : "",
      rushHourStartTime: item.closeTime
        ? getFormattedTime(item.rushHourStartTime)
        : "",
      rushHourEndTime: item.closeTime
        ? getFormattedTime(item.rushHourEndTime)
        : "",
    }));

    const payload = {
      ...locationData,
      openDays,
    };

    const res = await api("put", Constants.END_POINT.LOCATION + id, payload);
    if (res.success) {
      dispatch(getLocations());
      history.goBack();
      dispatch(showToast({ severity: "success", summary: res.message }));
    }
    setLoading(false);
  };

const EnableDisableLocation = (id, active) => async (dispatch) => {
  const payload = { isActive: active };
  const res = await api("put", Constants.END_POINT.LOCATION + id, payload);
  if (res.success) {
    dispatch(getLocations());
    dispatch(showToast({ severity: "success", summary: res.message }));
  }
};
const getLocation = (id, returnData) => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await api("get", Constants.END_POINT.LOCATION + id);
  if (res.success) {
    if (res.data) {
      if (returnData) {
        returnData(res.data);
      }
    }
  }
  dispatch(hideLoaderAction());
};

const reorderLocation = (locations, setLoading, next) => async (dispatch) => {
  let payload = locations.map((item) => item._id);
  setLoading(true);
  const res = await api(
    "post",
    Constants.END_POINT.LOCATION + "reorder",
    payload
  );
  setLoading(false);
  if (res.success) {
    if (next) {
      next();
    }
  }
};

const getConnectURL = (path, setLoading, next) => async (dispatch) => {
  let payload = {
    redirectUrl: path,
  };
  setLoading(true);
  const res = await api(
    "post",
    Constants.END_POINT.LOCATION + "ready-to-order/get-connect-url",
    payload
  );
  setLoading(false);
  if (res.success) {
    if (next) {
      next(res.data);
    }
  }
};
const connectURL = (id, token, next) => async (dispatch) => {
  let payload = {
    accountAccessToken: token,
  };
  dispatch(showLoaderAction());
  const res = await api(
    "post",
    Constants.END_POINT.LOCATION + `ready-to-order/connect/${id}`,
    payload
  );
  if (res) {
    next();
  }

  dispatch(hideLoaderAction());
};

const getTempBusySlotsInLocation = (loaction, next) => async (dispatch) => {
  const res = await api(
    "get",
    Constants.END_POINT.LOCATION_BUSY_SLOT + loaction
  );
  if (res.success) {
    next(res.data);
  }
};
const removeTempBusySlotsInLocation = (loaction, next) => async (dispatch) => {
  const res = await api(
    "delete",
    Constants.END_POINT.LOCATION_BUSY_SLOT + loaction
  );
  if (res.success) {
    next(res.data);
  }
};
const addTempBusySlotsInLocation =
  (data, setLoading, next) => async (dispatch) => {
    setLoading(true);

    let payload = {
      startTime: getFormattedTime(data?.startTime),
      endTime: getFormattedTime(data?.endTime),
    };

    const res = await api(
      "post",
      Constants.END_POINT.LOCATION_BUSY_SLOT + data.location,
      payload
    );
    if (res.success) {
      dispatch(showToast({ severity: "success", summary: res.message }));
      next();
    }
    setLoading(false);
  };

export {
  getLocations,
  addBranchLocation,
  getLocation,
  editBranchLocation,
  EnableDisableLocation,
  reorderLocation,
  getConnectURL,
  connectURL,
  addTempBusySlotsInLocation,
  getTempBusySlotsInLocation,
  removeTempBusySlotsInLocation,
};
